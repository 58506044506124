/*!

 File: set-ajax-modal-form.js
 Author: Ember
 Version: 1.0.19 [CCS-826] - file field
 JS Dependencies:
    'focus-first'  - helper,
    'helper-on' - helper,
    'triggerEvent' - helper,
    'closest' - polyfill

 Description:
    This is a Vanilla JS version of the ADMIN set-ajax-form.js
*/

var setAjaxModalForm = {
    elTarget: null, // store reference to clicked button
    init: function (selector) {

        var me = setAjaxModalForm;

        var elModal = document.querySelector('#ajax-form-modal');
        var elModalTitle;

        var openEvt = function(e){

            var elTarget = e.target;

            // Closest captures self as well a parents
            if (!elTarget.closest(selector)) return;

            // Button clicked
            e.preventDefault();

            me.elTarget = elTarget;

            if(elModal){
                // Get modal title
                var dataTitle = me.elTarget.getAttribute('data-modal-title');
                // URL might be further up the tree
                if(!dataTitle || dataTitle==''){
                    var elDataTitle = me.elTarget.closest('div[data-modal-title]');
                    if(elDataTitle){
                        dataTitle = elDataTitle.getAttribute('data-modal-title');
                    }
                }

                var titleText = (dataTitle && dataTitle != '') ? dataTitle : 'Edit Entry';
                if(elModalTitle){
                    elModalTitle.innerText = titleText;
                }

                // allows data-attribute and data-attribute="true"  otherwise returns false
                if (checkDataAttr(me.elTarget, 'data-query-string', true)) {
                    // Add querystring param to show which modal was opened
                    if('URL' in window && 'URLSearchParams' in window) {
                        var url = new URL(window.location);
                        var btnId = me.elTarget.getAttribute('data-id');
                        if(btnId){
                            url.searchParams.set('modal', btnId);
                            if(typeof window.history.replaceState != 'undefined'){
                                history.replaceState(null, document.title, url.href);
                            }
                        }
                    }
                }

                me.openModal(elModal);
            }

        }

        if(elModal) {

            elModalTitle = elModal.querySelector('.js-modal-title');

            // Attach event
            on('click', document, openEvt);

            // Init the close button
            var elCloseBtn = elModal.querySelector('.js-modal-close');
            on('click', elCloseBtn, function(e){
                e.preventDefault();
                $.magnificPopup.close();
            });

            // Init the save button
            me.initSaveEditForm(elModal);

            //
            // Check for query string and open modal if necessary - (NEEDs IE POLYFILL)
            if('URL' in window && 'URLSearchParams' in window) {
                var url = new URL(window.location);
                if(url.searchParams.has('modal')){
                    var modalId = url.searchParams.get('modal');
                    var elQueryBtn = document.querySelector(selector + '[data-id="' + modalId + '"]');
                    //
                    if(elQueryBtn){
                        triggerEvent(elQueryBtn,'click');
                    }
                }
            }

        }

    },

    // Open Modal
    openModal: function (elModal) {

        var me = setAjaxModalForm;

        me.setSaveBtnText(elModal);
        
        // Open function to be sent to 'openMpForm' as callback
        var openCallback = function(){
            me.loadAjaxForm(elModal);
        }

        // Close function to be sent to 'openMpForm' as callback
        var closeCallback = function(){
            // remove any modal querystrings
            if('URL' in window && 'URLSearchParams' in window) {
                var url = new URL(window.location);
                if(url.searchParams.has('modal')){
                    url.searchParams.delete('modal');
                    if(typeof window.history.replaceState != 'undefined'){
                        history.replaceState(null, document.title, url.href);
                    }
                }
            }
        }

        // Trigger magnific popup form
        openMpForm($(elModal), true, true, openCallback, closeCallback);

    },

    // load contents of modal form
    loadAjaxForm: function(elModal){
        var me = setAjaxModalForm;

        var elResultsContainer = elModal.querySelector('.js-ajax-modal-results'),
            elResultsContainerInner = elModal.querySelector('.js-ajax-modal-results-inner'),
            elSpinner = elModal.querySelector('.js-spinner'),
            elSaveButton = elModal.querySelector('.js-modal-save');

        // Reset HTML
        elResultsContainerInner.innerHTML = '';

        // Thinking...
        elSpinner.classList.add('css-spinner-show');
        elSaveButton.disabled = true;
        elSaveButton.classList.add('disabled');

        // find alert block and reset it
        var elAlert = elModal.querySelector('.js-alert');
        if(elAlert){
            formAlert.reset(elAlert);
        }

        // Get url
        var formUrl = me.elTarget.getAttribute('data-form-url');

        // URL might be further up the tree
        if(!formUrl || formUrl==''){
            var elFormUrl = me.elTarget.closest('div[data-form-url]');
            if(elFormUrl){
                formUrl = elFormUrl.getAttribute('data-form-url');
            }
        }

        // A new save URL may have been stored in the modal
        elResultsContainerInner.removeAttribute('data-update-url');

        if(formUrl){

            // Param object
            var params = {};

            // Element ID
            // If data attr exists and isn't empty
            if (checkDataAttr(me.elTarget, 'data-id')) {
                params['id'] = me.elTarget.getAttribute('data-id');
            }

            // Add any extra data to Params
            var extraData = me.elTarget.getAttribute('data-extra');
            if(extraData && extraData != ''){
                extraData = JSON.parse(extraData);
                for (var i in extraData) {
                    params[i] = extraData[i];
                }
            }

            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // Pause for effect
            setTimeout(function(){

                // AJAX request
                axios.post(formUrl, params, config)
                    .then(function (response) {

                        var responseJson = response.data;

                        // Stop Thinking...
                        elSpinner.classList.remove('css-spinner-show');

                        // Returned Success
                        if(responseJson.status=='success'){
                            // Set HTML
                            elResultsContainerInner.innerHTML = responseJson.html;

                            // Get height
                            var h = elResultsContainerInner.offsetHeight;

                            var animatingClass = h < 200 ? 'animating-fast' : (h > 600 ? 'animating-slow' : 'animating');

                            // Hide results
                            elResultsContainerInner.style.display = 'none';
                            elResultsContainerInner.style.maxHeight = '20px';
                            
                            elResultsContainerInner.classList.add('animating');
                            var animSpeed = h < 1200 ? (h < 400 ? 400 : h) : 1200; // Clamp between 400 and 1200
                            elResultsContainerInner.style.transitionDuration = animSpeed + 'ms';
                            
                            // Init fields
                            initFields($(elResultsContainerInner));

                            // show results
                            elResultsContainerInner.style.display = 'block';
                            elResultsContainerInner.offsetHeight; // force reflow
                            elResultsContainerInner.style.maxHeight = h + 'px';

                            // Reset for next time
                            setTimeout(function(){
                                elResultsContainerInner.classList.remove('animating');
                                elResultsContainerInner.removeAttribute('transition-duration');
                                elResultsContainerInner.style.maxHeight = '';
                                // focus first field
                                if (typeof focusFirst === 'function') {
                                    focusFirst(elResultsContainerInner);
                                }
                            }, animSpeed+200);

                            // Enable save button
                            elSaveButton.disabled = false;
                            elSaveButton.classList.remove('disabled');

                        }

                        // Returned Error
                        if(responseJson.status=='error'){

                            // See if a message was returned
                            if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                errorMessage = responseJson.message.text;
                            }

                            // show error in alert
                            if(elAlert){
                                formAlert.show(elAlert, errorMessage, null, 'danger');    
                            }

                        }

                    })
                    .catch(function (error) {
                        // Stop Thinking...
                        elSpinner.classList.remove('css-spinner-show');

                        if(error.response){
                            errorMessage  += '<br>' + error.response;
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }
                    });

            }, 400);

        }else{
            console.log('set-ajax-modal-form.js missing data-form-url');
        }


    },

    // Save event for the AJAX loaded form
    initSaveEditForm: function(elModal){

        var me = setAjaxModalForm;

        var elSaveButton = elModal.querySelector('.js-modal-save');

        // Form save events
        var elEditForm = elModal.querySelector('form'),
            // Our validation library is still jquery reliant
            $editForm = $('#ajax-form-modal form').first();

        if(!elEditForm){
            console.log('WARNING: form element is missing!');
        }

        $editForm.validate({
            submitHandler: function (form) {
                var cbFunction = function(){
                    me.formSubmit(elModal, elEditForm);
                }
                // If data attr exists and isn't empty
                if (checkDataAttr(me.elTarget, 'data-pre-submit-callback')) {
                    var callback = me.elTarget.getAttribute('data-pre-submit-callback');
                    if (typeof window[callback] === 'function') {
                        window[callback](form, cbFunction);  
                    }
                }else{
                    cbFunction();
                } 
                
            }
        });

        on('click', elSaveButton, function(e){
            e.preventDefault();
            // trigger submit
            $editForm.submit();
        });

    },

    // Submitting the edit form and dealing with response
    formSubmit: function(elModal, elEditForm){
        var me = setAjaxModalForm;

        // Get save elements
        var elSaveButton = elModal.querySelector('.js-modal-save'),
            elFormContainer = elModal.querySelector('.form-disable'),
            elResultsContainerInner = elModal.querySelector('.js-ajax-modal-results-inner');

        // Get url
        var saveFormUrl = me.elTarget.getAttribute('data-update-url');

        // URL might be further up the tree
        if(!saveFormUrl || saveFormUrl==''){
            var elSaveFormUrl = me.elTarget.closest('[data-update-url]');
            if(elSaveFormUrl){
                saveFormUrl = elSaveFormUrl.getAttribute('data-update-url');
            }
        }

        // If a form has been returned then a new save URL may have been stored in the modal
        var returnedSaveUrl = elResultsContainerInner.getAttribute('data-update-url');
        if(returnedSaveUrl && returnedSaveUrl != ''){
            saveFormUrl = returnedSaveUrl;
        }

        // Start save
        if(saveFormUrl){

            var params;
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr(me.elTarget, 'data-just-submit-enabled', true)) {
                // Serialise all enabled fields
                params = serializeArray(elEditForm);
            }else{
                // Serialise all fields (including disabled)
                params = serializeArray(elEditForm, true);
            }

            // Are we uploading files?
            var elFileList = elEditForm.querySelectorAll('[type=file]');

            if(elFileList && elFileList.length>0){
                // Need to send as a formData object if form may contains images to upload
                // Not supported in <IE10
                var thisFormData = new FormData();
    
                // Loop through serialised array and add each to form data object
                // ES6 object loop
                Object.keys(params).forEach(function (item) {
                    thisFormData.append(item, params[item]);
                });
    
                // Now append any file fields
                forEachNode(elFileList, function (i, el) {
                    var files = el.files;
                    thisFormData.append(el.getAttribute('name'), files[0]);
                });
    
                // Set AJAX options
                params = thisFormData;
    
            }

            // Thinking...
            pendingButton.show(elSaveButton);
            pendingForm.disable(elFormContainer);

            // find alert block and reset it
            var elAlert = elModal.querySelector('.js-alert');
            if(elAlert){
                formAlert.reset(elAlert);
            }

            // Set XMLHttpRequest header - required for PHP CI is_ajax_request() to work
            var config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }

            // Default error message
            var errorMessage = 'Sorry, there was a problem with your request.';

            // AJAX request
            axios.post(saveFormUrl, params, config)
                .then(function (response) {

                    var responseJson = response.data;

                    // Stop Thinking...
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elFormContainer);

                    // Returned Success
                    if(responseJson.status=='success'){

                        // A secondary form may be returned that will need processing
                        var newFormData = responseJson.form;

                        if(newFormData && newFormData.html != ''){

                            if(newFormData.url && newFormData.url != ''){
                                // Add new URL to be picked up on next save cycle
                                elResultsContainerInner.setAttribute('data-update-url', newFormData.url);
                            }else{
                                // remove any lingering URLS
                                elResultsContainerInner.removeAttribute('data-update-url');
                            }

                            // Thinking...
                            pendingButton.show(elSaveButton);
                            pendingForm.disable(elFormContainer);

                            // Animation start point
                            var h = elResultsContainerInner.offsetHeight;
                            elResultsContainerInner.style.maxHeight = h + 'px';
                            elResultsContainerInner.classList.add('animating');
                            elResultsContainerInner.offsetHeight; // force reflow

                            // Start animation
                            elResultsContainerInner.style.maxHeight = '0';

                            setTimeout(function(){
                                // Set new HTML
                                elResultsContainerInner.innerHTML = newFormData.html;

                                // Init fields
                                initFields($(elResultsContainerInner));

                                // Get the new height (by opening and closing quickly)
                                elResultsContainerInner.classList.remove('animating');
                                elResultsContainerInner.offsetHeight; // force reflow
                                elResultsContainerInner.style.maxHeight = '';
                                h = elResultsContainerInner.offsetHeight;
                                elResultsContainerInner.style.maxHeight = '0';
                               
                                elResultsContainerInner.classList.add('animating');
                                var animSpeed = h < 1200 ? (h < 400 ? 400 : h) : 1200; // Clamp between 400 and 1200
                                elResultsContainerInner.style.transitionDuration = animSpeed + 'ms';

                                // pause for effect
                                setTimeout(function(){
                                    // Run animation
                                    elResultsContainerInner.style.maxHeight = h + 'px';
                                    // Reset for next time
                                    setTimeout(function(){
                                        elResultsContainerInner.classList.remove('animating');
                                        elResultsContainerInner.removeAttribute('transition-duration');
                                        elResultsContainerInner.style.maxHeight = '';
                                        // focus first field
                                        if (typeof focusFirst === 'function') {
                                            focusFirst(elResultsContainerInner);
                                        }
                                        // Stop Thinking...
                                        pendingButton.hide(elSaveButton);
                                        pendingForm.enable(elFormContainer);

                                    }, animSpeed+200);

                                }, 200);

                            }, 400);

                            return;
                        }

                        // No secondary form is returned

                        // pause for effect
                        setTimeout(function(){

                            // There might be a redirect url on the button or in the response
                            var redirectUrl = null;
                            // If data attr exists and isn't empty
                            if (checkDataAttr(me.elTarget, 'data-redirect-url')) {
                                redirectUrl = me.elTarget.getAttribute('data-redirect-url');
                            }

                            if (responseJson.redirecturl && responseJson.redirecturl != ''){
                                redirectUrl = responseJson.redirecturl;
                            }

                            if (redirectUrl) {
                                window.location.href = redirectUrl;
                                return;
                            }

                            // Close modal
                            $.magnificPopup.close();

                            // After close
                            setTimeout(function(){

                                // Show notification
                                var responseMessage;
                                //
                                if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                                    Notify({
                                        text: responseJson.message.text,
                                        status: 'success',
                                        offset: 100
                                    }).showNotify();
                                }

                                // If I am in a datatable then replace content (still JQUERY)
                                $target = $(me.elTarget);
                                var $dtParent = $target.closest('.js-datatable');
                                if($dtParent.length>0){
                                    var $dtRow = $target.closest('tr');
                                    setDataTable.updateRow($dtParent, $dtRow, response.data);
                                }

                                // Process any domUpdate content
                                if(responseJson.updates && responseJson.updates.length){
                                    responseJson.updates.forEach(function (item) {
                                        var elUpdate = document.querySelector('#' + item.id);
                                        if(elUpdate){
                                            if(item.html && item.html!=''){
                                                elUpdate.innerHTML = item.html;
                                            }
                                            if(item.delete){
                                                elUpdate.parentNode.removeChild(elUpdate);
                                            }
                                        }
                                    });
                                }

                                // Check for callback
                                var callback = me.elTarget.getAttribute('data-callback');

                                // Callback might be further up the tree
                                if(!callback || callback==''){
                                    var elCallback = me.elTarget.closest('[data-callback]');
                                    if(elCallback){
                                        callback = elCallback.getAttribute('data-callback');
                                    }
                                }

                                // Run callback
                                if (typeof window[callback] === 'function') {
                                    window[callback](me.elTarget, response.data);
                                }

                            }, 400);

                        }, 800);
                    }

                    // Returned Error
                    if(responseJson.status=='error'){

                        // See if a message was returned
                        if(responseJson.message && responseJson.message.text && responseJson.message.text != ''){
                            errorMessage = responseJson.message.text;
                        }

                        // scroll to top of modal
                        var elScroll = document.querySelector('.mfp-wrap');
                        if(elScroll){
                            elScroll.scrollTo(0, 0);
                        }

                        // show error in alert
                        if(elAlert){
                            formAlert.show(elAlert, errorMessage, null, 'danger');
                        }

                        // Re init fields
                        initFields($(elResultsContainerInner));

                    }

                })
                .catch(function (error) {
                    // Stop Thinking...
                    pendingButton.hide(elSaveButton);
                    pendingForm.enable(elFormContainer);

                    if(error.response){
                        errorMessage  += '<br>' + error.response;
                    }

                    // show error in alert
                    if(elAlert){
                        formAlert.show(elAlert, errorMessage, null, 'danger');
                    }

                });
        }else{
            console.log('set-ajax-modal-form.js missing data-update-url');
        }

    },

    setSaveBtnText: function(elModal){
        var me = setAjaxModalForm;

        // set save button text
        var elSaveButton = elModal.querySelector('.js-modal-save');

        // Get save button text
        var dataSaveBtnTxt = me.elTarget.getAttribute('data-modal-save-text');
        // Text might be further up the tree
        if(!dataSaveBtnTxt || dataSaveBtnTxt==''){
            var elDataSave = me.elTarget.closest('div[data-modal-save-text]');
            if(elDataSave){
                dataSaveBtnTxt = elDataSave.getAttribute('data-modal-save-text');
            }
        }
        
        if(elSaveButton){
            if(dataSaveBtnTxt && dataSaveBtnTxt!=''){
                elSaveButton.innerText = dataSaveBtnTxt;
            }
            // allows data-attribute and data-attribute="true"  otherwise returns false
            if (checkDataAttr(me.elTarget, 'data-modal-hide-save', true)) {
                elSaveButton.style.display = 'none';
            }else{
                // reset
                elSaveButton.style.display = '';
            }
        }

        
    }

}
