/*!

 File: set-multi-select-columns.js
 Author: Ember
 Version: 1.0.2 [CC-456] // Max items
 JS Dependencies:
    

 Description:
    Make an ajax request on button click, then display a toastr
    This is a Vanilla JS version of the ADMIN set-ajax-button.js

*/

;(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], function () {
			return factory(root);
		});
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.MSColumn = factory(root);
	}
})(typeof global !== 'undefined' ? global : typeof window !== 'undefined' ? window : this, function (window) {

    'use strict';

    var MSColumn = (function () {

        var Constructor = function () {

    		//
    		// Variables
    		//

    		var publicAPIs = {};

            var settings = {};

            var elContainer,
                elChoices,
                elValScroll,
                elValUl,
                elValIntro;

            var initialMousePosition,
                containerRect,
                dragRect,
                modalRect;

            var maxItems;

    		//
    		// Methods
    		//

    		/**
    		 * A private method
    		 */

            // convoluted dragging constraints
            var translateMirror = function(mirror, mirrorCoords, containerRect) {
                if (mirrorCoords.top < containerRect.top || mirrorCoords.top > containerRect.bottom) {
                //if (mirrorCoords.top < containerRect.top || mirrorCoords.left < containerRect.left) {
                    return;
                }

                var top = mirrorCoords.top;
                var left = mirrorCoords.left;

                // Account for modal
                if(modalRect){ 
                    top = top - modalRect.top; 
                    left =  left - modalRect.left;
                }

              
                requestAnimationFrame(() => {
                    mirror.style.transform = 'translate3d(' + left + 'px, ' + top  + 'px, 0)';
                });
            }

            var calcOffset = function(offset) {
                return offset * 2 * 0.5;
            }

    		var initMSColumn = function (el) {

                elContainer = el;
                elValScroll = el.querySelector('.msc-values .msc-scroll');
                elValUl = el.querySelector('.msc-values .msc-scroll ul');

                //elValIntro =  el.querySelector('.js-msc-intro-values');
                //if(elValIntro){
                    //elValIntro.classList.add('animate');
                //}

                // Attach open event
                elChoices = elContainer.querySelector('.msc-choices');
                if(elChoices) {
                    elChoices.classList.add('animate');
                    on('click', elChoices, choicesClickEvt);
                }

                // attach remove event
                if(elValUl) {
                    on('click', elValUl, valuesClickEvt);
                }

                // Check max
                maxItems = el.dataset.max;
                checkMax(el);

                // make rows sortable
                var elSortable = el.querySelector('.js-msc-sortable');

                var sortable = new Draggable.Sortable(elSortable, {
                    draggable: 'li',
                    mirror: {
                        constrainDimensions: true,
                    },
                    sortAnimation: {
                        duration: 200,
                        easingFunction: 'ease-in-out'
                    },
                    plugins: [Draggable.Plugins.SortAnimation]
                });

                sortable.on('mirror:created', (evt) => {
                    containerRect = evt.sourceContainer.getBoundingClientRect();
                    dragRect = evt.source.getBoundingClientRect();
                    //
                    var elModal = evt.sourceContainer.closest('.mfp-modal');
                    if(elModal){
                        modalRect = elModal.getBoundingClientRect();
                    }
                    
                });

                sortable.on('drag:start', function(evt){
                    // Allow buttons to be clickable
                    var currentTarget = evt.originalEvent.target;
                    if(currentTarget.closest('button') || currentTarget.closest('a')){
                        evt.cancel();
                    }

                    initialMousePosition = {
                        x: evt.sensorEvent.clientX,
                        y: evt.sensorEvent.clientY,
                    };
                });

                sortable.on('drag:stop', function(evt){
                    sortOrderUpdated();
                });

                sortable.on('mirror:move', function(evt){
                    // Required to help restrict the draggable element to the container
                    evt.cancel();

                    // We do not want to use `getBoundingClientRect` while dragging,
                    // as that would be very expensive.
                    // Instead, we look at the mouse position, which we can ballpark as being
                    // close to the center of the draggable element.
                    // We need to look at both the X and Y offset and determine which is the higher number.
                    // That way we can drag outside of the container and still have the
                    // draggable element move appropriately.
                   // var offsetX = calcOffset(evt.sensorEvent.clientX - initialMousePosition.x);
                    var offsetY = calcOffset(initialMousePosition.y - evt.sensorEvent.clientY);
                    //var offsetValue = offsetX > offsetY ? offsetX : offsetY;
                    var mirrorCoords = {
                        top: dragRect.top - offsetY, 
                        left: dragRect.left
                    };

                    translateMirror(evt.mirror, mirrorCoords, containerRect);
                });

                // check sort order
                sortOrderUpdated();

            };

            var choicesClickEvt = function (e) {

                var el = e.target.closest('li');
                
                // Closest captures self as well a parents
                if (!el) return;

                // Button clicked
                e.preventDefault();

                if(!el.classList.contains('rel-field-disabled')){
                    if(!checkMax()){
                        addRow(el);
                    };
                }

            };

            var valuesClickEvt = function (e) {

                var el = e.target.closest('.js-msc-remove');

                // Closest captures self as well a parents
                if (!el) return;

                // Button clicked
                e.preventDefault();

                
                var elLi = el.closest('li');
                if(elLi){
                    removeRow(elLi)
                }
                
            };


            var addRow = function (el) {
                // Disable button
                el.classList.add('rel-field-disabled');

                // get template html
                var rowHtml = elContainer.querySelector('.js-msc-blank-row').innerHTML;
                // Create a container li for our HTML that we can target later
                var elNewLi = document.createElement('li');
                elNewLi.innerHTML = rowHtml;

                elNewLi.classList.add('hide-anim');// Hide before showing

                // Set Content
                var elTitleSrc = el.querySelector('.js-msc-item-title');
                var elTitle = elNewLi.querySelector('.js-msc-item-title');
                if(elTitleSrc && elTitle) {
                    elTitle.innerText = elTitleSrc.innerText;
                }

                // Set image
                var elImgSrc = el.querySelector('.js-msc-item-thumb');
                var elImg = elNewLi.querySelector('.js-msc-item-thumb');
                if(elImgSrc && elImg) {
                    elImg.src = elImgSrc.src;
                }
                
                // Set hidden field val
                var elInput = elNewLi.querySelector('input');
                if(elInput){
                    var value = el.getAttribute('data-value');
                    elInput.value = value;
                    var name = el.getAttribute('data-name');
                    elInput.setAttribute('name', name + '[0]');
                }else{
                    console.log('missing input');
                }
                
                // Add row
                elValUl.appendChild(elNewLi);
                // Next tick
                elValUl.offsetHeight; // force reflow

                // Show new item
                elNewLi.classList.remove('hide-anim')
                elNewLi.classList.add('highlight');

                // Reset after anim
                setTimeout(function(){
                    elNewLi.classList.remove('highlight');
                }, 500);


                // update order
                sortOrderUpdated();

            }


            var removeRow = function (el){
                // get value
                var elInput = el.querySelector('input');
                var val = elInput.value;
                // find corresponding li
                var elChoiceItem = elChoices.querySelector('li[data-value="' + val + '"]');
                
                // Enable button
                elChoiceItem.classList.remove('rel-field-disabled');

                // Fade out item
                el.classList.add('hide-anim');

                // Set heights
                var height = el.offsetHeight;
                el.style.maxHeight = (height+1) +'px';
                el.style.overflowY = 'hidden';

                setTimeout(function(){
                    el.style.maxHeight = 0;
                    setTimeout(function(){
                        // Remove this item
                        el.parentNode.removeChild(el);
                        // update order
                        sortOrderUpdated();
                        // Check max
                        checkMax();
                    }, 500);
                }, 200);

            }

            var sortOrderUpdated = function (el) {
                var elValueItems = elValUl.querySelectorAll('li');

                // This regex will find the last instance of a digit in square brackets within a string
                var regex = new RegExp(/\[\d](?!.*\[)/);

                if(elValueItems && elValueItems.length>0){

                    elContainer.classList.add('show-drag-message');

                    forEachNode(elValueItems, function (i, el) {
                        var elInput = el.querySelector('input');
                        if(elInput){
                            var name = elInput.name;
                            elInput.name = name.replace(regex, '[' + i + ']');
                        }
                    });

                }else{
                    //elContainer.classList.remove('show-drag-message');
                    /*if(elValIntro){
                        elValIntro.classList.remove('show');
                    }*/
                }
            }

            var checkMax = function(){
                var maxReached = false;
                if(maxItems && maxItems > 0){
                    var elLiList = elValUl.querySelectorAll('li');
                    if(elLiList.length >= maxItems){
                        maxReached = true;
                        elContainer.classList.add('max-reached');
                    }else{
                        elContainer.classList.remove('max-reached');
                    }
                }
                return maxReached;
            }


    		/**
            * Public methods
            */
    		publicAPIs.init = function (options) {

                // Verifying and validating the input object
                if (!options) {
                    options = {};
                }

                // Validating the options
                settings.el = options.el || null;
                if(settings.el){

                    settings.type = options.type || null;

                    initMSColumn(settings.el);

                }else{
                    console.log('set-multi-select-columns.js is missing a dom element');
                }

                // Returning the current object for chaining functions
                return this;

    		};


    		//
    		// Return the Public APIs
    		//

    		return publicAPIs;

    	};

        //
    	// Return the Constructor
    	//

    	return Constructor;


    })();

    // Returning the MSColumn function to be assigned to the window object/module
    return MSColumn;

});
